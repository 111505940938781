//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from '../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin'

export default BaseCmsBlock.extend({
  name: "VideoBlock",

  data: function () {
    return {
      isClick: false,
      screenWidth: 0,
    }
  },

  mounted() {
    this.screenWidth = window.innerWidth;
  },

  computed: {
    title: function () {
      return this.values?.title || ""
    },

    imageId: function () {
      return this.screenWidth <= 986 ? (this.values?.image_mobile || 0) : (this.values?.image || 0)
    },
    getContent: function () {
      return this.values?.video_html || '';
    },
  },

  methods: {
    playVideo: function () {
      this.isClick = true;
    }
  }
});
